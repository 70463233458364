<template>
  <section class="pa2 pa4-ns bg-near-white">
    <div class="center mw9-ns">
      <h2>Índice HOMA-IR</h2>
      <p class="pa1 lh-copy">
        Medir a cintura conforme o esquema abaixo:
      </p>
      <p>
        <!--
          <img src="https://www.wikihow.com/images/thumb/8/80/Measure-Belly-Fat-Step-2.jpg/v4-728px-Measure-Belly-Fat-Step-2.jpg.webp">
          <img src="https://www.wikihow.com/images/thumb/0/03/Measure-Belly-Fat-Step-5.jpg/v4-728px-Measure-Belly-Fat-Step-5.jpg.webp">
          <img src="https://carolahoisel.com/wp-content/uploads/2020/07/Boneca-de-medidas.jpg">
        -->
        <img src="https://image.shutterstock.com/image-vector/women-waist-weight-loss-diet-600w-1369752044.jpg">
      </p>

      <p class="lh-copy">
        Com um valor de <strong :class="indiceHomaClass">{{ indiceHomaValor }}</strong> seu índice HOMA sugere
        <strong :class="indiceHomaClass" class="ttu">{{ indiceHomaStatus }}</strong>.
      </p>

      <p class="pa3 f3 bg-red white"
         v-if="indiceHomaValor > 0.5">
         Reduza o consumo de
         <strong>TRIGO</strong>,
         <strong>AÇÚCAR</strong> e
         <strong>ALCOOL</strong>
         enquanto o índice HOMA estiver acima de 0.5.
      </p>
      <p v-else class="pa3 f3 bg-yellow">
        Você está em ótima forma!
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Profile',
  components: {
  },
  computed: {
    indiceHomaValor() { return (this.$store.state.cintura / this.$store.state.altura).toFixed(2); },
    indiceHomaStatus() {
      if( this.indiceHomaValor <= 0.50 ) return "Inflamação ausente";
      if( this.indiceHomaValor <= 0.55 ) return "Inflamação leve";
      if( this.indiceHomaValor <= 0.60 ) return "Inflamação moderada";
      return "Inflamação grave";
    },
    indiceHomaClass() {
      if( this.indiceHomaValor <= 0.50 ) return "green";
      if( this.indiceHomaValor <= 0.55 ) return "orange";
      if( this.indiceHomaValor <= 0.60 ) return "red";
      return "red";
    }
  }
}
</script>
