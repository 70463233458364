<template>
  <section class="pa2 pa4-ns bg-near-white tc">
    <div class="center mw9-ns">
      <datalist id="alimento_datalist">
        <option v-for="alimento in taco" :key="alimento.n">{{ alimento.n }}</option>
      </datalist>

      <datalist id="quantidades_tipicas_datalist">
        <option v-for="q in quantidades_tipicas" :value="q.massa" :key="q.massa">{{ q.massa + 'g - ' + q.nome }}</option>
      </datalist>

      <div class="cf">
        <label class="fl w-100 pv2" for="alimento">Escolha um alimento</label>
        <input class="fl w-100 pv1" v-model="alimento_tmp" type="text" id="alimento" name="alimento" list="alimento_datalist" />

        <label class="fl w-100 pv2" for="alimento">Escolha a quantidade em gramas</label>
        <input class="fl w-100 pv1 tc"  v-model="gramas" list="quantidades_tipicas_datalist" type="number" />
      </div>

      <div v-show="alimento" class="mt2 cf pv2 tc">
        <div class="w-100 f4 pv2 dark-green">{{ alimento }} </div>
        <DisplayProp nome="Energia" :valor="energia" unidade="kcal" />
        <DisplayProp nome="Carboidrato" :valor="carboidrato" />
        <DisplayProp nome="Proteina" :valor="proteina" />
        <DisplayProp nome="Gordura" :valor="gordura" />
      </div>

      <p>
        A <a
            target="_blank"
            href="http://www.unicamp.br/nepa/taco/contar/taco_4_edicao_ampliada_e_revisada.pdf?arquivo=taco_4_versao_ampliada_e_revisada.pdf"
          >
          Tabela de Composição de Alimentos
        </a>
        elaborada pela Unicamp é uma das mais completas e recomendadas,
        apresentando as referências em porções de 100 gramas.
        <!--
        Para saber do valor nutricional de cada alimento que compõe a receita,
        é preciso fazer outra regra de três a partir do valor da tabela.
        -->
      </p>


    </div>
  </section>
</template>

<script>
// Tabelas de alimentos
import taco from '../../data/Taco_4a_edicao_2011_recorte_02.json';
// [
//   {
//     "n": "Arroz, integral, cozido",  Nome
//     "u": 70.1,                       Umidade
//     "e": 124,                        Energia
//     "p": 2.6,                        Proteina
//     "l": 1.0,                        Gordura
//     "c": 25.8                        Carboidrato
//   },

import receitas from '../../data/receitas.json';
import DisplayProp from '@/components/DisplayProp.vue';

export default {
  name: 'Foods',
  components: {
    DisplayProp
  },
  data () {
    return {
      taco: taco,
      receitas: receitas,
      quantidadesTipicas: {
        "n": "Ovo, de galinha, inteiro, cru",
        "q": [ { "massa": 34, "nome": "1 unidade" } ]
      },
      quantidades_tipicas: [],
      alimento_tmp: '',
      alimento: '',
      gramas: 100,
      energia: 0,
      carboidrato: 0,
      proteina: 0,
      gordura: 0
    }
  },
  watch: {
    alimento_tmp(novoValor) {
      // A cada caracter digitado a função é chamada
      this.atualizarGramasPadraoParaAlimentoSelecionado();
      this.atualizarDadosParaAlimentoSelecionado();
    },
    gramas(novoValor) {
      if( this.alimento ) {
        this.atualizarDadosParaAlimentoSelecionado();
      }
    }
  },
  methods: {
    atualizarGramasPadraoParaAlimentoSelecionado() {

    },
    atualizarDadosParaAlimentoSelecionado() {
      this.alimento    = null;
      this.energia     = 0;
      this.carboidrato = 0;
      this.proteina    = 0;
      this.gordura     = 0;

      if( this.alimento_tmp ) {
        let alimentoSelecionado = this.dadosDoAlimento( this.alimento_tmp );

        if( alimentoSelecionado.length == 1 ) {
          this.alimento    = this.alimento_tmp;
          this.energia     = (alimentoSelecionado[0].e * this.gramas * 0.01).toFixed(0);
          this.carboidrato = (alimentoSelecionado[0].c * this.gramas * 0.01).toFixed(0);
          this.proteina    = (alimentoSelecionado[0].p * this.gramas * 0.01).toFixed(0);
          this.gordura     = (alimentoSelecionado[0].l * this.gramas * 0.01).toFixed(0);
          return;
        }
      }
    },
    dadosDoAlimento(nome) {
      return this.taco.filter(function (item) { return item.n === nome });
    },
  },
}
</script>
