import { createStore } from 'vuex'

export default createStore({
  state: {
    // Dados iniciais
    idade: 18,
    nome: 'Seu nome',
    sexo: 'F',
    massa: 45.0,
    altura: 150,
    cintura: 40,
    refeicoes: []
  },
  mutations: {
    carregarDadosIniciais(state) {
      console.log('Carregando dados iniciais');
      if( localStorage.altura )  { state.altura  = localStorage.altura; }
      if( localStorage.cintura ) { state.cintura = localStorage.cintura; }
      if( localStorage.massa )   { state.massa   = localStorage.massa; }
      if( localStorage.nome )    { state.nome    = localStorage.nome; }
      if( localStorage.sexo )    { state.sexo    = localStorage.sexo; }
      if( localStorage.idade )   { state.idade   = localStorage.idade; }
      if( localStorage.quantidadesTipicas ) { state.quantidadesTipicas = JSON.parse( localStorage.quantidadesTipicas ); }
      if( localStorage.refeicoes ) { state.refeicoes = JSON.parse( localStorage.refeicoes ); }
    },
    inserirItemDaRefeicao(state, refeicao) {
      state.refeicoes.push(refeicao);
      localStorage.refeicoes = JSON.stringify(state.refeicoes);
    },
    removerItemDaRefeicao(state, key) {
      state.refeicoes = state.refeicoes.filter( function(refeicao) {
        return refeicao.key != key;
      });
      localStorage.refeicoes = JSON.stringify(state.refeicoes);
    },
    atualizarNome(state, nome) {
      state.nome = nome;
      localStorage.nome = nome;
    },
    atualizarAltura(state, altura) {
      state.altura = altura;
      localStorage.altura = altura;
    },
    atualizarCintura(state, cintura) {
      state.cintura = cintura;
      localStorage.cintura = cintura;
    },
    atualizarMassa(state, massa) {
      state.massa = massa;
      localStorage.massa = massa;
    },
    atualizarIdade(state, idade) {
      state.idade = idade;
      localStorage.idade = idade;
    },
    atualizarSexo(state, sexo) {
      state.sexo = sexo;
      localStorage.sexo = sexo;
    },
  },
  actions: {
  },
  modules: {
  }
})
