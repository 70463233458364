<template>
  <div class="w-100 vh-100">
    <div id="home-mob" class="dn-ns cover bg-center w-100 h-100"></div>
    <div id="home-desk" class="dn db-ns cover bg-center w-100 h-100"></div>
    <!--
    <div class="hide">
      <img class="w-50 w-10-ns mv5" alt="Low Carb" src="@/assets/logo.svg">
      <h1 class="near-black">Low Carb</h1>
      <h2 class="silver">2022</h2>
    </div>
    -->
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped>
#home-mob {
  background-image: url('/low_carb_wallpaper_mob.webp');
  background-repeat: no-repeat;
}
#home-desk {
  background-image: url('/low_carb_wallpaper.webp');
  background-repeat: no-repeat;
}
  /*
  background-position: center center;
  position: absolute;
  height: 100%; width: 100%
   */
</style>
