<template>
  <div>
    <!-- Navegação -->
    <div id="nav" class="bg-dark-green near-white pv3 tc center">
      <router-link class="link near-white" to="/">
        Home
      </router-link> |
      <router-link class="link near-white" to="/perfil">
        Perfil
      </router-link> |
      <router-link class="link near-white" to="/homa">
        HOMA
      </router-link> |
      <router-link class="link near-white" to="/alimentos">
        Alimentos
      </router-link> |
      <router-link class="link near-white" to="/refeicoes">
        Refeições
      </router-link>
      <!-- <router-link class="link near-white" to="/about"> -->
      <!--   Sobre -->
      <!-- </router-link> -->
    </div>

    <!-- Conteúdo da página -->
    <router-view />

    <footer class="bg-dark-green center tc near-white pv5 h5">
      Links para redes sociais
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted () {
    this.$store.commit('carregarDadosIniciais');
  }
}
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
*/

#nav .router-link-active {
  color: white;
}

#nav .router-link-exact-active {
  color: white;
  font-weight: 900;
}

</style>
