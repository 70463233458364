<template>
  <div class="pa2">
      <h2 class="tc">Ingredientes das Refeições</h2>

      <datalist id="alimento_datalist">
        <option v-for="alimento in taco" :key="alimento.n">{{ alimento.n }}</option>
      </datalist>

      <div class="cf">
        <label class="fl w-100 pv2" for="alimento">Escolha um alimento</label>
        <input class="fl w-100 pv1" v-model="alimento_tmp" type="text" id="alimento" name="alimento" list="alimento_datalist" />

        <label  v-if="alimento" class="fl w-100 pv2" for="quantidade">Quantidade em gramas</label>
        <input  v-if="alimento" class="fl w-50 pv2 tc bn" v-model="quantidade" type="number" />
        <button v-if="alimento" class="fl w-50 pv2 bg-green near-white b bn" @click="adicionarAlimento">Adicionar</button>
      </div>

      <p v-if="!tabela" @click="tabela = !tabela">Ver como tabela</p>
      <p v-else @click="tabela = !tabela">Ver como lista</p>

      <div v-if="!tabela" class="mt4">
        <div class="pv1 cf" v-for="ingrediente in this.$store.state.refeicoes" :key="ingrediente.key">
          <div class="fl tc w-100 tc b">{{ ingrediente.q }}g de {{ ingrediente.nome }}</div>
          <div class="fl tc w-50">Energia:&nbsp;{{ (dadosDoIngrediente(ingrediente.nome, ingrediente.q).e).toFixed(0) }}kcal</div>
          <div class="fl tc w-50">Carboidrato:&nbsp;{{ (dadosDoIngrediente(ingrediente.nome, ingrediente.q).c).toFixed(0) }}g</div>
          <div class="fl tc w-50">Proteina:&nbsp;{{ (dadosDoIngrediente(ingrediente.nome, ingrediente.q).p).toFixed(0) }}g</div>
          <div class="fl tc w-50">Gordura:&nbsp;{{ (dadosDoIngrediente(ingrediente.nome, ingrediente.q).l).toFixed(0) }}g</div>
        </div>
      </div>
      <div v-else class="mt4">
      <table class="mt4 collapse center pa1 w-100 w-50-ns">
        <tr>
          <!--
          <th>Hora</th>
          -->
          <th>Qtd</th>
          <th>Ingrediente</th>
          <th>E</th>
          <th>C</th>
          <th>P</th>
          <th>G</th>
        </tr>
        <tr v-for="ingrediente in this.$store.state.refeicoes" :key="ingrediente.key">
          <!--
          <td class="pa1">{{ moment(ingrediente.key).format('hh:mm') }}</td>
          -->
          <td class="ba b--silver pv2 tc">{{ ingrediente.q }}g</td>
          <td class="ba b--silver pv2 tl">{{ ingrediente.nome }}</td>
          <td class="ba b--silver pv2 tc">{{ (dadosDoIngrediente(ingrediente.nome, ingrediente.q).e).toFixed(0) }}</td>
          <td class="ba b--silver pv2 tc">{{ (dadosDoIngrediente(ingrediente.nome, ingrediente.q).c).toFixed(0) }}</td>
          <td class="ba b--silver pv2 tc">{{ (dadosDoIngrediente(ingrediente.nome, ingrediente.q).p).toFixed(0) }}</td>
          <td class="ba b--silver pv2 tc">{{ (dadosDoIngrediente(ingrediente.nome, ingrediente.q).l).toFixed(0) }}</td>
          <td class="ba b--silver pv2 tc"><button class="bg-near-white silver bn" @click="removerItemDaRefeicao(ingrediente.key)">🗑</button></td>
        </tr>
        <tr>
          <td class="ba b--silver pv2 tc b">{{ (total().q).toFixed(0) }}g</td>
          <td class="ba b--silver pv2 tl b">Total</td>
          <td class="ba b--silver pv2 tc b">{{ (total().e).toFixed(0) }}</td>
          <td class="ba b--silver pv2 tc b">{{ (total().c).toFixed(0) }}</td>
          <td class="ba b--silver pv2 tc b">{{ (total().p).toFixed(0) }}</td>
          <td class="ba b--silver pv2 tc b">{{ (total().l).toFixed(0) }}</td>
          <td class=""></td>
        </tr>
      </table>
    </div>

      <!--
      <div v-for="receita in receitas" :key="receita.nome">
        <h3>{{ receita.nome }}</h3>

        <table class="center outline">
          <tr>
            <th>qtd</th>
            <th>nome</th>
            <th>C</th>
            <th>P</th>
            <th>G</th>
          </tr>
          <tr v-for="ingrediente in receita.ingredientes" :key="ingrediente[0]">
            <td>{{ ingrediente[1] }}g</td>
            <td>{{ ingrediente[0] }}</td>
            <td>{{ (dadosDoIngrediente(ingrediente).c).toFixed(0) }}</td>
            <td>{{ (dadosDoIngrediente(ingrediente).p).toFixed(0) }}</td>
            <td>{{ (dadosDoIngrediente(ingrediente).l).toFixed(0) }}</td>
          </tr>
        </table>
      </div>
      -->
  </div>
</template>

<script>
import moment from 'moment';
import taco from '../../data/Taco_4a_edicao_2011_recorte_02.json';
import receitas from '../../data/receitas.json';

export default {
  name: 'Meals',
  data () {
    return {
      moment: moment,
      taco: taco,
      tabela: true,
      receitas: receitas,
      alimento: null,
      quantidade: 10,
      alimento_tmp: ''
    }
  },
  watch: {
    alimento_tmp(novoValor) {
      // A cada caracter digitado a função é chamada
      // this.atualizarGramasPadraoParaAlimentoSelecionado();
      // this.atualizarDadosParaAlimentoSelecionado();

        let alimentoSelecionado = this.dadosDoAlimento( this.alimento_tmp );
        this.alimento = null;
        if( alimentoSelecionado.length == 1 ) {
          this.alimento = alimentoSelecionado[0];
        }
    },
  },
  methods: {
    removerItemDaRefeicao(key) {
      this.$store.commit('removerItemDaRefeicao', key );
    },
    adicionarAlimento() {
      let key = this.agora();
      let ingrediente = {"key": key, "nome": this.alimento.n, "q": this.quantidade };
      this.$store.commit('inserirItemDaRefeicao', ingrediente);
      this.alimento_tmp = '';
    },
    agora() {
      return moment().toISOString();
    },
    dadosDoAlimento(nome) {
      return this.taco.filter(function (item) { return item.n === nome });
    },
    dadosDoIngrediente(nome, qtd) {
      let ret = { "n": nome, "u": 0, "e": 0, "p": 0, "l": 0, "c": 0 };
      let alimento100g = this.dadosDoAlimento(nome)[0];
      ret.u = alimento100g.u * qtd / 100;
      ret.e = alimento100g.e * qtd / 100;
      ret.p = alimento100g.p * qtd / 100;
      ret.l = alimento100g.l * qtd / 100;
      ret.c = alimento100g.c * qtd / 100;

      // Dados que não existem na tabela estão indicados com -1
      if( ret.u < 0 ) ret.u = 0;
      if( ret.e < 0 ) ret.e = 0;
      if( ret.c < 0 ) ret.c = 0;
      if( ret.p < 0 ) ret.p = 0;
      if( ret.l < 0 ) ret.l = 0;

      return ret;
    },
    total() {
      let ret = { "q": 0, "u": 0, "e": 0, "p": 0, "l": 0, "c": 0 };
      let dadosDoIngrediente = this.dadosDoIngrediente;
      this.$store.state.refeicoes.forEach(function(ingrediente) {
        let i = dadosDoIngrediente(ingrediente.nome, ingrediente.q);
        ret.q += ingrediente.q;
        ret.u += i.u;
        ret.e += i.e;
        ret.c += i.c;
        ret.p += i.p;
        ret.l += i.l;
      });
      return ret;
    }
  },
  computed: {
  }
}
</script>
