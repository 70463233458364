<template>
  <div class="fl w-100 w-25-ns pa2 pa3-ns bg-dark-green tc pa2 white ba">
    <div class="f2 w9-ns center near-white">
      <!-- Valores não presentes na tabela TACO estão indicados com valor negativo -->
      <span v-if="valor > 0">{{ valor }} {{ unidade }}</span>
      <span v-else>---</span>
    </div>
    <div class="w9-ns center near-white">
      {{ nome }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nome',
  props: {
    nome: {
      type: String,
      default: 'Propriedade'
    },
    valor: {
      type: Number,
      default: 999
    },
    unidade: {
      type: String,
      default: 'g'
    }
  },
  setup () {
  }
}
</script>
