<template>
  <div class="bg-white">
    <section class="pa1 pa4-ns">
      <div class="center mw9-ns">
        <h1>
          <input
            v-model="nome"
            class="f1 tc bn w-100 dark-green"
          >
        </h1>
        <div class="cf f3">
          <div class="fl w-100 w-third-ns">
            <label
              class="fl w-50 tr green"
              for="massa"
            >Peso:</label>
            <input
              id="massa"
              v-model="massa"
              class="fl w-50 ph1 fw6 tl bn dark-green"
              min="30"
              max="200"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
            >
          </div>

          <div class="fl w-100 w-third-ns">
            <label
              class="fl w-50 tr green"
              for="altura"
            >Altura:</label>
            <input
              id="altura"
              v-model="altura"
              class="fl w-50 ph2 fw6 tl bn dark-green"
              min="130"
              max="230"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
            >
          </div>

          <div class="fl w-100 w-third-ns">
            <label
              class="fl w-50 tr green"
              for="cintura"
            >Cintura:</label>
            <input
              id="cintura"
              v-model="cintura"
              class="fl w-50 ph2 fw6 tl bn dark-green"
              min="60"
              max="200"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
            >
          </div>

          <div class="fl w-100 w-third-ns">
            <label
              class="fl w-50 tr green"
              for="idade"
            >Idade:</label>
            <input
              id="idade"
              v-model="idade"
              class="fl w-50 ph2 fw6 tl bn dark-green"
              min="18"
              max="100"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
            >
          </div>

          <div class="fl w-100 w-third-ns">
            <label
              class="fl w-50 tr green"
              for="sexo"
            >Sexo:</label>
            <select
              v-model="sexo"
              class="w-50 ph2 bn bg-white dark-green b"
            >
              <option
                class="bg-white"
                value="M"
              >
                Masculino
              </option>
              <option
                class="bg-pink"
                value="F"
              >
                Feminino
              </option>
            </select>
          </div>
        </div>
      </div>
    </section>

    <section class="pa2 pa4-ns">
      <div class="center mw9-ns">
        <h2 class="tc green">Limites diários</h2>
        <table class="outline center collapse ba br2 b--black-10 pv2 ph3">
          <thead>
            <tr>
              <th class="tl pa2 dark-green">Item</th>
              <th class="tl pa2 dark-green">Mínimo</th>
              <th class="tl pa2 dark-green">Máximo</th>
            </tr>
          </thead>
          <tbody>
            <tr class="striped--light-gray">
              <td class="tl pa2 green">Proteina</td>
              <td class="tc pa2 dark-green b">{{ minProteina }} g</td>
              <td class="tc pa2 dark-green b">{{ maxProteina }} g</td>
            </tr>
            <tr class="striped--light-gray">
              <td class="tl pa2 green">Gordura</td>
              <td class="tc pa2 dark-green b">{{ minGordura }} g</td>
              <td class="tc pa2 dark-green b">{{ maxGordura }} g</td>
            </tr>
            <tr class="striped--light-gray">
              <td class="tl pa2 green">Carboidrato emagrecimento</td>
              <td class="tc pa2 dark-green b">{{ minCarboidratoEmagrecimento }} g</td>
              <td class="tc pa2 dark-green b">{{ maxCarboidratoEmagrecimento }} g</td>
            </tr>
            <tr class="striped--light-gray">
              <td class="tl pa2 green">Carboidrato manutenção</td>
              <td class="tc pa2 dark-green b">{{ minCarboidratoManutencao }} g</td>
              <td class="tc pa2 dark-green b">{{ maxCarboidratoManutencao }} g</td>
            </tr>
            <tr class="striped--light-gray">
              <td class="tl pa2 green">Calorias emagrecimento</td>
              <td class="tc pa2 dark-green b">{{ minCaloriasEmagrecimento }} kcal</td>
              <td class="tc pa2 dark-green b">{{ maxCaloriasEmagrecimento }} kcal</td>
            </tr>
            <tr class="striped--light-gray">
              <td class="tl pa2 green">Calorias manutenção</td>
              <td class="tc pa2 dark-green b">{{ minCaloriasManutencao }} kcal</td>
              <td class="tc pa2 dark-green b">{{ maxCaloriasManutencao }} kcal</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

  </div>
</template>

<script>

import InputAltura from '@/components/InputAltura';

export default {
  name: 'Profile',
  components: {
    // InputAltura
  },
  computed: {
    nome: {
      get() { return this.$store.state.nome; },
      set(valor) { this.$store.commit('atualizarNome', valor); }
    },
    massa: {
      get() { return this.$store.state.massa; },
      set(valor) { this.$store.commit('atualizarMassa', valor); }
    },
    altura: {
      get() { return this.$store.state.altura; },
      set(valor) { this.$store.commit('atualizarAltura', valor); }
    },
    cintura: {
      get() { return this.$store.state.cintura; },
      set(valor) { this.$store.commit('atualizarCintura', valor); }
    },
    idade: {
      get() { return this.$store.state.idade; },
      set(valor) { this.$store.commit('atualizarIdade', valor); }
    },
    sexo: {
      get() { return this.$store.state.sexo; },
      set(valor) { this.$store.commit('atualizarSexo', valor); }
    },

    minProteina() { return (this.massa * 1.0).toFixed(0); },
    maxProteina() { return (this.massa * 1.5).toFixed(0); },
    minGordura() { return (this.massa * 0.8).toFixed(0); },
    maxGordura() { return (this.massa * 1.0).toFixed(0); },
    minCarboidratoEmagrecimento() { return (80).toFixed(0); },
    maxCarboidratoEmagrecimento() { return (100).toFixed(0); },
    minCarboidratoManutencao() { return (100).toFixed(0); },
    maxCarboidratoManutencao() { return (150).toFixed(0); },
    minCaloriasEmagrecimento() { return (this.minProteina * 4 + this.minGordura * 9 + this.minCarboidratoEmagrecimento * 4).toFixed(0); },
    maxCaloriasEmagrecimento() { return (this.maxProteina * 4 + this.maxGordura * 9 + this.maxCarboidratoEmagrecimento * 4).toFixed(0); },
    minCaloriasManutencao() { return (this.maxProteina * 4 + this.maxGordura * 9 + this.minCarboidratoManutencao * 4).toFixed(0); },
    maxCaloriasManutencao() { return (this.maxProteina * 4 + this.maxGordura * 9 + this.maxCarboidratoManutencao * 4).toFixed(0); },
  }
}
</script>
